import React, { useState, useEffect, memo, useRef, useCallback, useMemo } from "react";
import { FixedSizeGrid as Grid } from "react-window";
import io from "socket.io-client";
import "./index.css";

const totalCheckboxes = 1000000;
const socket = io("https://einemillionboxen.de", { 
  withCredentials: true,
  path: '/socket.io/',
  transports: ['websocket', 'polling']
});

function App() {
  const [checkedItems, setCheckedItems] = useState({});
  const [userCount, setUserCount] = useState(0);
  const [userColor, setUserColor] = useState("");
  const [checkedCount, setCheckedCount] = useState(0);
  const [lastChecked, setLastChecked] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [lastCheckedTime, setLastCheckedTime] = useState(null);
  const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false);
  const containerRef = useRef(null);
  const gridRef = useRef(null);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [cellSize, setCellSize] = useState(40);

  const updateSize = useCallback(() => {
    if (containerRef.current) {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setContainerSize({ width, height });

      let newCellSize;
      if (width < 640) {
        newCellSize = Math.max(Math.floor(width / 10), 30);
      } else if (width < 1024) {
        newCellSize = Math.max(Math.floor(width / 20), 25);
      } else {
        newCellSize = Math.max(Math.floor(width / 40), 20);
      }
      setCellSize(newCellSize);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [updateSize]);

  useEffect(() => {
    socket.on("initialState", (data) => {
      setCheckedItems(data.checkboxStates);
      setUserColor(data.userColor);
      setCheckedCount(data.checkedCount);
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      setLastCheckedTime(data.lastCheckedTime);
      setLastChecked(data.lastChecked);
    });

    socket.on("checkboxUpdate", (data) => {
      setCheckedItems((prevState) => {
        if (!prevState[data.id]) {
          return {
            ...prevState,
            [data.id]: { checked: true, color: data.color },
          };
        }
        return prevState;
      });
      setCheckedCount(data.checkedCount);
      setLastChecked(data.id);
      setLastCheckedTime(data.lastCheckedTime);
      if (data.endDate) {
        setEndDate(data.endDate);
      }
    });

    socket.on("userCountUpdate", (count) => {
      setUserCount(count);
    });

    socket.on("setCookie", (data) => {
      document.cookie = `${data.name}=${data.value}; max-age=${data.maxAge}; path=/`;
    });

    return () => {
      socket.off("initialState");
      socket.off("checkboxUpdate");
      socket.off("userCountUpdate");
      socket.off("setCookie");
    };
  }, []);

  const handleChange = (id) => {
    if (checkedItems[id]?.checked) return;

    setCheckedItems((prevState) => ({
      ...prevState,
      [id]: { checked: true, color: userColor },
    }));
    socket.emit("checkboxChange", { id });
  };

  const jumpToLastChecked = useCallback(() => {
    if (lastChecked !== null && gridRef.current) {
      const columnsPerRow = Math.floor(containerSize.width / cellSize);
      const rowIndex = Math.floor(lastChecked / columnsPerRow);
      const columnIndex = lastChecked % columnsPerRow;
      gridRef.current.scrollToItem({
        align: "center",
        rowIndex,
        columnIndex
      });
    }
  }, [lastChecked, containerSize.width, cellSize]);

  const Cell = memo(({ columnIndex, rowIndex, style }) => {
    const index =
      rowIndex * Math.floor(containerSize.width / cellSize) + columnIndex;
    if (index >= totalCheckboxes) return null;

    const id = index;
    const isChecked = !!checkedItems[id]?.checked;
    const checkboxColor = checkedItems[id]?.color || userColor;

    return (
      <div style={style} className="flex items-center justify-center">
        <input
          type="checkbox"
          id={`checkbox-${id}`}
          checked={isChecked}
          onChange={() => handleChange(id)}
          disabled={isChecked}
          className="form-checkbox cursor-pointer transition-all duration-200 ease-in-out hover:scale-110"
          style={{
            width: `${cellSize * 0.8}px`,
            height: `${cellSize * 0.8}px`,
            outline: isChecked ? `4px solid ${checkboxColor}` : "none",
            outlineOffset: "2px",
            borderRadius: "0%",
          }}
        />
      </div>
    );
  });

  const LiveUpdate = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
      if (lastChecked !== null) {
        setVisible(true);
        const timer = setTimeout(() => setVisible(false), 3000);
        return () => clearTimeout(timer);
      }
    }, [lastChecked]);

    if (!visible) return null;

    return (
      <div className="fixed bottom-4 right-4 bg-blue-500 text-white px-3 py-1 rounded-full text-xs animate-pulse">
        Kästchen {lastChecked} wurde angekreuzt
      </div>
    );
  };

  const calculateLeaderboard = useMemo(() => {
    const colorCounts = {};
    Object.values(checkedItems).forEach(item => {
      if (item.color in colorCounts) {
        colorCounts[item.color]++;
      } else {
        colorCounts[item.color] = 1;
      }
    });
    return Object.entries(colorCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 5)
      .map(([color, count]) => ({ color, count }));
  }, [checkedItems]);

  const Leaderboard = () => {
    if (calculateLeaderboard.length === 0) {
      return <p>No data available</p>;
    }

    return (
      <ul className="mt-2">
        {calculateLeaderboard.map((entry, index) => (
          <li key={index} className="flex justify-between items-center mb-1">
            <span style={{ color: entry.color }}>■</span>
            <span>{entry.color}</span>
            <span>{entry.count}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="h-screen w-screen bg-gray-100 flex flex-col">
      <div className="p-2 sm:p-4 bg-white shadow-md">
        <h1 className="text-xl sm:text-2xl font-bold text-center mb-2 text-gray-800">
          Eine Million Kästchen
        </h1>
        <div className="flex flex-wrap justify-between items-center text-xs sm:text-sm mb-2">
          <span>
            Spieler: <b>{userCount}</b>
          </span>
          <span>
            Farbe: <b style={{ color: userColor }}>{userColor}</b>
          </span>
          <span>
            Angekreuzt: <b>{checkedCount}</b>
          </span>
          <button
            onClick={jumpToLastChecked}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-xs sm:text-sm mt-1 sm:mt-0"
          >
            Zum letzten Kreuz springen
          </button>
        </div>
        <div className="text-xs sm:text-sm space-y-1">
          <p>Startdatum: {startDate || "Nicht verfügbar"}</p>
          <p>Letztes Kreuz: {lastCheckedTime || "Noch kein Kreuz"}</p>
          <p>Enddatum: {endDate || "Noch nicht beendet"}</p>
        </div>
        <div className="mt-2">
          <button
            onClick={() => setIsLeaderboardOpen(!isLeaderboardOpen)}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded text-xs sm:text-sm"
          >
            {isLeaderboardOpen ? "Top Spieler ausblenden" : "Top Spieler anzeigen"}
          </button>
          {isLeaderboardOpen && (
            <div className="mt-2 bg-white p-2 rounded shadow-md">
              <h2 className="text-lg font-bold mb-2">Leaderboard</h2>
              <Leaderboard />
            </div>
          )}
        </div>
      </div>
      <div ref={containerRef} className="flex-grow overflow-hidden">
        <Grid
          ref={gridRef}
          columnCount={Math.floor(containerSize.width / cellSize)}
          rowCount={Math.ceil(
            totalCheckboxes / Math.floor(containerSize.width / cellSize)
          )}
          columnWidth={cellSize}
          rowHeight={cellSize}
          height={containerSize.height - 200} // Adjusted for the header
          width={containerSize.width}
          className="bg-white"
        >
          {Cell}
        </Grid>
      </div>
      <LiveUpdate />
    </div>
  );
}

export default App;